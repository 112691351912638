import React, { Component } from "react";
import "./WelcomeNew.css";
import configurations from "../../../configurations";
import axios from "axios";
import Loader from "../../loader";
import AccountCircle from '@material-ui/icons/AccountCircle';

import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

import PrivacyStatement from "./PrivacyStatement";
import Grid from "@material-ui/core/Grid";
import MyLoader from '../../authentication/loader'
import moment from "moment";
export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: {},
      loaderShow: false,
      visible: false,
      quick_quote_url: '',
      enrollment_url: '',
      employerDetails: {},
      STATE_PARAM: {},
      showQuickQuote: false,
      showLoader: true,
    };
  }

  componentDidMount() {
    this.setState({
      loaderShow: true,
    });
    let pathname = window.location.pathname;
    let val = pathname.split("/");
    let agentID, empid = '';
    if (val.length === 5) {
      agentID = val[val.length - 2];
      empid = val[val.length - 1];
    } else {
      agentID = val[val.length - 1];
    }

    axios
      .get(configurations.baseUrl + "/agentlogin/getAgentById/" + agentID)
      .then((response) => {
        console.log("desc===", response);
        if (response.data.response) {
          this.setState(
            {
              agent: response.data.response,
              loaderShow: false,
            },
            () => {
              this.state.agentEmail = this.state.agent.email;
              this.state.agentPhone = this.state.agent.phone;
            }
          );

          if (empid) {
            axios
              .get(
                process.env.REACT_APP_BASE_URL +
                  "/employer/getEmployerByEmpId/" +
                  empid
              )
              .then((response) => {
                console.log(response);
                if (response.data.response) {
                  this.setState({
                    employerDetails: response.data.response,
                  });
                }
              });
          }
          let quickLink = `${process.env.REACT_APP_QUICK_ESIT_LINK}?channelID=${response.data.response.clientId}&agentID=${response.data.response.brokerId}&agentEmail=${response.data.response.email}`;

          this.setState({
            quick_quote_url:quickLink,
            STATE_PARAM: {
              clientId: response.data.response.clientId,
              associationId: response.data.response.associationId,
              brokerId: response.data.response.brokerId,
              empid: empid
            },
          });
            
          // axios
          //   .post(configurations.baseUrl + "/encrypt/encryptData", obj)
          //   .then((response) => {
          //     let quick_quote_url =
          //       configurations.enrollmentURL +
          //       "/quick_quote" +
          //       "#state=" +
          //       response.data.response;

          //     // let quick_quote_url =
          //     //   configurations.enrollmentURL +
          //     //   "/quick_estimate"+
          //     //   "#state=" +
          //     //   response.data.response;

          //     let enrollment_url =
          //       configurations.enrollmentURL +
          //       "/signup" +
          //       "#state=" +
          //       response.data.response;
          //     this.setState({
          //       loaderShow: false,
          //       quick_quote_url: quick_quote_url,
          //       enrollment_url: enrollment_url,
          //     });
          //   });
        }
      });
  }

  launchEnrollment = () => {
    window.open(
      `${process.env.REACT_APP_EMPLOYER_PORTAL}/auth/signup?ch=${btoa(
        this.state.STATE_PARAM.clientId
      )}&ag=${btoa(this.state.STATE_PARAM.brokerId)}&emp=&mode=non-trial`
    );
  };

  quickquote = (url) => {
    this.setState({ showQuickQuote: true, showLoader: true })
  }

  launchBrouchure(url) {
    window.open(url);
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <div class="welcome">
        {this.state.loaderShow ? <Loader></Loader> : ""}
        <div class="welcome_topcontainer">
          <div class="welcome_firstcontainer">
          
                <img
                width={'25%'}
                  src={require("../../../Assets/Images/Curam_Logo.png")}
                  className="welcome_main_logo"
                />
             
            <div class="welcome_middlecontainer">
              <div class="welcome_floral_containerdiv">
                <div class="welcome_floral_middle_div">
                  <div class="welcome_middle_firstcontainer">
                    <div class="welcome_header_curamlife">
                      Your small business clients can retain and recruit their
                      best employees and 1099s affordably with health care and
                      wellness access from
                      <img
                        width={"15%"}
                        src={require("../../../Assets/Images/Curam_Logo.png")}
                        className="welcome_main_logo"
                      />
                      .
                    </div>

                    <div class="welcome_paragraph">
                      CuramLife provides packages of employee{" "}
                      <b>virtual health care</b> and <b>wellness</b> access and
                      a one-of-a-kind <b>concierge</b> service to employers,
                      small business owners, and entrepreneurs to offer their
                      employees and 1099 workers (their families, too).
                    </div>

                    <div class="welcome_paragraph">
                      Adding health care access helps small business avoid huge
                      turnover costs by retaining the best workers, recruiting
                      the best new ones, and improving the employee experience.
                    </div>
                    <div class="welcome_paragraph">
                      And the time and money savings over administering and
                      managing a traditional health plan are huge! CuramLife is
                      an employee health care program that’s actually affordable
                      for small business!
                    </div>
                    <div class="welcome_paragraph">
                      CuramLife packages of virtual health care and wellness run
                      from $5 to $60 per employee per month.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="new_secondcontainer">
            <div class="welcome_association_conatiner">
              <div></div>
              {this.state.agent.associationImage ? (
                <div class="welcome_association">
                  <img
                    src={this.state.agent.associationImage}
                    class="welcome_association_image"
                  />
                </div>
              ) : null}
            </div>
            <div>
              {this.state.employerDetails &&
              this.state.employerDetails.companyName ? (
                <div class="createdBg">
                  <h4>Created for:</h4>
                  <h2>{this.state.employerDetails.companyName}</h2>
                </div>
              ) : (
                ""
              )}
            </div>

            <div class="infoWrapper">
              {this.state.agent.image ? (
                <img
                  src={this.state.agent.image}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    padding: "5px",
                  }}
                />
              ) : (
                <AccountCircle style={{ height: "80px", width: "80px" }} />
              )}

              <div class="welcome_username">
                {this.state.agent.firstName + " " + this.state.agent.lastName}
              </div>
              <div class="welcome_number">{this.state.agent.phone}</div>
              <div class="welcome_email">{this.state.agent.email}</div>

              {this.state.agent.description && (
                <div class="welcome_description">
                  {this.state.agent.description}
                </div>
              )}
            </div>
            <div class="new_bottom_center ">
                <div class="welcome_button_container">
                  <button type="button" class="btn welcome_enroll_button" onClick={()=>this.launchEnrollment()}>
                      ENROLL TODAY!
                  </button>
                </div>
                <div class="welcome_button_container2">
                  <button type="button" class="btn welcome_enroll_button" onClick={()=>this.quickquote(this.state.quick_quote_url)}>
                  QUICK ESTIMATE
                  </button>
                </div>
              {/* <div>
                <div class="welcome_button_container2">
                  <button
                    type="button"
                    class="btn2 welcome_quick_button"
                    // disabled={true}
                    style={{ border: "solid 2px #4782C4" }}
                  >
                    {/*onClick={() => this.launchEnrollment("quick_quote")}*/}
                    {/*<a
                      href={this.state.quick_quote_url}
                      style={{ textDecoration: "none", color: "#4782C4" }}
                      target="_blank"
                    >
                      GET A QUICK QUOTE
                    </a>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div style={{ flexGrow: 1 }} className="welcome_footer_container">
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid xs={12} sm={12} item={true} style={{ display: "flex" }}>
              <Grid item={true} xs={10} sm={10}>
                <div className="welcome_copyright">
                  Copyright © 2019-{moment().format("YY")} CuramLife. All Rights Reserved
                </div>
              </Grid>
              
              <Grid item={true} xs={2} sm={2}>
                <div
                  className="welcome_privacypolicy"
                  onClick={() =>
                     this.setState({ visible: true })
                  }
                >
                  Privacy Policy
                </div>
              </Grid>
            </Grid>
           
          </Grid>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "end",
            fontSize: "12px",
            backgroundColor: "#e3e3e3",
          }}
        >
          CID : {this.state.STATE_PARAM.clientId} OID:{" "}
          {this.state.STATE_PARAM.associationId}, BID :{" "}
          {this.state.STATE_PARAM.brokerId}, EID :{" "}
          {this.state.employerDetails.companyName
            ? this.state.employerDetails.companyName
            : "NA"}
        </div>

        {/* ============================Modal Pop Up Privacy policy=================== */}

        <Modal
          show={this.state.visible}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{maxWidth:'450px',marginLeft:'25%',}}
          // dialogClassName="modal_welcome_container"
          onHide={(event) => this.setState({ visible: false })}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#33afb0" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy Policy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 220px)",
              // "max-width": "calc(50vh - 300px)",
              "overflow-y": "auto",
            }}
          >
            <PrivacyStatement />

            <div class="text-right ">
              <button
                class="launch"
                onClick={() => this.setState({ visible: false })}
              >
                CANCEL
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showQuickQuote}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="my-modal"
          // dialogClassName="modal_welcome_container"
          onHide={(event) => this.setState({ showQuickQuote: false })}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#201547", height: 50, alignItems: 'center' }}>
            <Modal.Title style={{ color: "#F3D03E", fontSize: 18, fontWeight: 'bold' }}>Quick Estimate</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "overflow-y": "auto",
              "padding": 0,
              "background-color": "#201547"
            }}
          >
            <MyLoader showLoader={this.state.showLoader} style={{height: '85vh'}} color={"secondary"} />
            <iframe
              src={this.state.quick_quote_url}
              title="Common Module"
              style={{
                width: '100%',
                height: "80vh",
                border: 'none',
                margin: 'auto'
              }}
              onLoad={() => this.setState({showLoader: false})}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const policytext1 =
  "Privacy is not a new concept.This data is critical to the person it belongs to. Data privacy and security binds individuals and industries together and runs complex systems in our society. From credit card numbers and social security numbers to email addresses and phone numbers, our sensitive, personally identifiable information is important. This sort of information in unreliable hands can potentially have far-reaching consequences.Companies or websites that handle customer information are required to publish their Privacy Policies on their business websites. If you own a website, web app, mobile app or desktop app that collects or processes user data, you most certainly will have to post a Privacy Policy on your website (or give in-app access to the full Privacy Policy agreement).";
