import React, { Component } from "react";

export default class PrivacyStatement extends Component {
  render() {
    return (
      <div>
        <h6 class="privacy_subheading">Privacy Statement </h6>
        <div>
          The effective date of this Privacy Statement is January 22, 2024.
        </div>
        <div class="privacy_paragraphh">
          CuramLife understands that when our clients, partners and other
          individuals provide personal data to us, they place their trust in us.
          We take this trust seriously and are committed to respecting every
          individual’s privacy and protecting the personal data we handle. This
          Privacy Statement describes our privacy practices to help you
          understand what personal data we collect, use, share, and transfer,
          and it explains the choices you can make regarding how your personal
          data is handled.
        </div>
        <div class="privacy_paragraphh">
          This Privacy Statement applies to the CuramLife websites, domains, or
          applications that reference or link to this Statement. We may
          supplement this Privacy Statement with additional terms that apply to
          a particular product, service or website (a “Supplemental Statement”).
          When we do, the terms of the Supplemental Statement will apply and
          will govern wherever they differ from the terms of this Privacy
          Statement.
        </div>

        <h6 class="privacy_subheading">How We Collect and Use Personal Data</h6>
        <div class="privacy_paragraphh">
          We collect and use personal data that you provide in order to operate
          our business, provide our products and services, send marketing and
          other communications, and comply with applicable laws and regulations.
          In addition to the data you provide to us directly, we may also
          process personal data about you that we receive from our affiliates,
          partners, clients, or third parties.
        </div>
        <div class="privacy_paragraphh">
          The types of personal data we process and how it may be used will
          depend on the purpose, including:
        </div>
        <div class="privacy_paragraphh">
          <u>Providing products and services</u>. – Contact details (such as
          name, email, address, company name, phone number and other such
          information) are used as needed to provide products and services to
          our clients and our client’s customers, including providing product
          support updates and notices;
        </div>
        <div class="privacy_paragraphh">
          <u>Improving our products and services</u>. – contact details are used
          to maintain quality controls and evaluate the performance of our
          products and services, including conducting customer satisfaction
          surveys;
        </div>
        <div class="privacy_paragraphh">
          <u>Conducting due diligence</u>. – contact details and publicly
          available information about financial or reputational status of a
          customer, client, or third party supplier/partner are used for
          performing required or appropriate due diligence;
        </div>
        <div class="privacy_paragraphh">
          <u>Generating sales and marketing leads</u>. – contact details,
          marketing preferences, publicly available social media information is
          used to maintain a client relationship management database and send
          relevant newsletters, solution updates, event notifications and other
          marketing communications;
        </div>
        <div class="privacy_paragraphh">
          <u>Managing relationships with clients, suppliers, and partners</u>. –
          Contact details and financial and payment information is used as
          needed to generate invoices, collect fees, execute contracts, make
          payments, satisfy contractual obligations, and otherwise maintain and
          manage relationships with clients, suppliers, and partners for the
          benefit of our customers and to support our delivery of products and
          service.
        </div>
        <div class="privacy_paragraphh">
          <u>Responding to inquiries or requests for information</u>. – Contact
          details are used for delivering electronic communications in response
          to inquiries and requests for information.
        </div>
        <div class="privacy_paragraphh">
          When we receive personal data about you from our clients in order to
          provide our services, CuramLife processes the personal data as
          instructed by our clients and in accordance with our contractual
          obligations. Those clients are responsible for complying with
          regulations or laws regarding notice, disclosure, and/or obtaining
          consent prior to transferring the personal data to us for processing.
        </div>

        <h6 class="privacy_subheading">How We Protect Your Personal Data</h6>
        <div class="privacy_paragraphh">
          We use commercially reasonable security procedures, ,technical
          measures, and organizational processes and protocols to protect
          against accidental or unlawful destruction, loss, disclosure, or use
          of personal data we handle. We limit access to and use of your
          personal data to authorized persons and trusted third parties who are
          bound by confidentiality obligations and have a reasonable need to
          know the information in order to facilitate or support our business
          operations and the delivery and performance of our products and
          services.
        </div>

        <h6 class="privacy_subheading">How Long We Retain Personal Data</h6>
        <div class="privacy_paragraphh">
          We retain your personal data only for as long as is necessary to
          fulfill the purpose for which the data was collected from you and in
          consideration of and compliance with applicable legal or regulatory
          requirements that such data be maintained for legitimate purposes. For
          example, where required by law for audits or to satisfy accounting
          requirements, and as needed to enforce our agreements or handle
          disputes. When personal data is no longer needed for the purpose it
          was collected or processed or to comply with a legal obligation, we
          securely destroy it.
        </div>

        <h6 class="privacy_subheading">How We Share Your Personal Data</h6>
        <div class="privacy_paragraphh">
          We do not sell or otherwise disclose personal data about our website
          visitors or others that interact with CuramLife or our products or
          services, except as described herein.
        </div>
        <div class="privacy_paragraphh">
          We may share your personal data with authorized CuramLife personnel
          with a need to know the information in order to process the personal
          data for the purpose we collected it. We also share personal data with
          third parties who are acting on our behalf in order to provide the
          products or services you request or to support our relationship with
          you. These third parties are not authorized by us to use or disclose
          the information except as necessary to perform services on our behalf
          pursuant to a contractual obligation or to comply with legal
          requirements. CuramLife requires such third parties to comply with
          applicable data protection and privacy laws and agree to implement and
          maintain appropriate technical and organizational security measures to
          safeguard the personal data.
        </div>
        <div class="privacy_paragraphh">Our sharing of data may include:</div>
        <div class="privacy_paragraphh">
          Sharing with any of our CuramLife subsidiaries, affiliates, and
          trusted third party suppliers/partners in order to perform our
          services or conduct our business operations;
        </div>
        <div class="privacy_paragraphh">
          Sharing with our professional advisors and insurers as appropriate to
          run and protect our business;
        </div>
        <div class="privacy_paragraphh">
          Sharing with legal authorities, law enforcement, government agencies,
          government officials when required by applicable laws or regulations
          or pursuant to legal process (including for national security
          purposes); when we believe disclosure is necessary or appropriate to
          prevent physical harm or financial loss or in connection with an
          investigation of suspected, potential, apparent or actual fraud or
          illegal activity; or when we believe that disclosure is necessary to
          protect our rights and interests or to protect your safety or the
          safety of others.
        </div>
        <div class="privacy_paragraphh">
          Sharing with appropriate third parties in connection with evaluating
          or engaging in the sale, transfer or financing of all or part of a
          CuramLife business or its assets, or in connection with a bankruptcy
          or insolvency proceeding.
        </div>

        <h6 class="privacy_subheading">
          How We Treat Personal Data from Individuals Outside the U.S.
        </h6>
        <div class="privacy_paragraphh">
          CuramLife is a an American enterprise organized and based in the U.S.
          Our products, services, and offerings are only available to parties
          within the U.S., and may be subject to further geographic or other
          limitations within the U.S. While we cannot prevent individuals from
          outside the U.S. from providing contact information to CuramLife via
          our contact forms, we will not knowingly use information from parties
          outside the U.S. and will periodically purge any individual contact
          data known to be from individuals outside the U.S. from our databases.
        </div>

        <h6 class="privacy_subheading">
          How We Respect Your Privacy in Marketing
        </h6>
        <div class="privacy_paragraphh">
          CuramLife uses various means of communication and tools to market its
          products and services. We provide commercial marketing email to
          persons who consent or where we have a legitimate interest and it is
          otherwise permissible under applicable law. You may opt-out of
          receiving our commercial marketing emails by using the “Unsubscribe”
          or “Opt-out” link in the commercial email or by contacting us at{" "}
          <a class="privacy_link_text" href="mailto:info@curamlife.com">info@curamlife.com</a>.
        </div>
        <div class="privacy_paragraphh">
          We strive to make your CuramLife experience the best it can be and may
          use various tools that provide us with information about how you and
          other visitors interact with our website so that we can tailor your
          CuramLife experience and improve our web properties. These tools may
          include Cookies and other automatic data collection tools, and by
          virtue of choosing to visit and interact with our website you are
          acknowledging and consenting to the use of such tools.
        </div>

        <h6 class="privacy_subheading">
          How To Request Access To Your Personal Data
        </h6>
        <div class="privacy_paragraphh">
          We rely on you to provide accurate, complete, and current personal
          data to us. If you need to correct or update the personal data you
          provided to us, we will respond in a timely manner to all reasonable
          requests to access, correct or delete your personal data. Requests and
          questions can be submitted to{" "}
          <a class="privacy_link_text " href="mailto:info@curamlife.com">
            info@curamlife.com
          </a>
          .
        </div>
        <h6 class="privacy_subheading">How to Contact Us</h6>
        <div class="privacy_paragraphh">
          If you have any questions or comments regarding this Privacy Statement
          or our privacy practices, or any concerns regarding a possible
          violation of this Privacy Statement, our practices or any applicable
          privacy law please contact CuramLife at{" "}
          <a class="privacy_link_text" href="mailto:info@curamlife.com">
            info@curamlife.com
          </a>{" "}
          and we will promptly respond.
        </div>

        <div class="privacy_paragraphh" style={{ fontStyle: "italic" }}>
          Our mailing address is:
        </div>
        <div class="privacy_addr">CuramLife</div>
        <div class="privacy_addr">Attn: Privacy Administrator</div>
        <div class="privacy_addr">3700 Mansell Road, Suite 250,</div>
        <div class="privacy_addr">Alpharetta, GA 30022</div>
        <div class="privacy_addr">United States</div>
        <div class="privacy_addr">
          <a
            class="privacy_link_text"
            target="_blank"
            href="https://www.curamlife.com/"
          >
            www.Curamlife.com
          </a>
        </div>

        <h6 class="privacy_subheading">Links to Other Websites</h6>
        <div class="privacy_paragraphh">
          As a convenience to our website visitors, we sometimes provide links
          to other websites. These linked sites are not under the control of
          CuramLife and we are not responsible for their content. You should
          review the privacy statements of any linked website to understand
          their privacy practices before using the site.
        </div>

        <h6 class="privacy_subheading">Children</h6>
        <div class="privacy_paragraphh">
          It is not our intent to collect personal data from children under the
          age of consent. Our websites are not designed to attract children and
          we request that children under the age of consent not submit personal
          data to us through our websites.
        </div>

        <h6 class="privacy_subheading">Updates to Our Privacy Statement</h6>
        <div class="privacy_paragraphh">
          We may update this Privacy Statement from time to time. When we update
          this statement, we will also update the effective date shown at the
          top. At any given point in time, only the then-current version of our
          Privacy Statement is effective, so please review it periodically to
          ensure that you are aware of any changes that may be made in the
          future.
        </div>
      </div>
    );
  }
}
