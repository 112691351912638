import React, { Component } from 'react';
import { createMuiTheme,
  MuiThemeProvider,withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import configurations from "../../configurations";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import styles from "../../Assets/CSS/stylesheet_UHS";
import axios from "axios";
import AIKB from './AIKB';
import LanguageAccordion from './LanguageAccordian';
import { Modal } from "react-bootstrap";
import Loader from '../authentication/loader';


const StyleTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "#ffffff",
    fontSize:'13px'
  }
})(Tooltip);


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding:'0px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const NextButton = withStyles(
    // customStyle.doneBtn
    customStyle.doneCuramBtn
)(Button);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft:'16px',
    paddingRight:'0px',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

// export default function CustomizedAccordions() {
//   const [expanded, setExpanded] = React.useState('panel1');

//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };
class CustomizedAccordions extends Component{
    constructor(props){
        super(props)
        
        this.state = {
            expanded: 'panel2',
            accordionData:[],
            agentURL: '',
            tooltipShow:false,
            isAgent: true,
            isMember: true,
            parchesUrl:'',
            quick_quote_url: '',
            enrollment_url:'',
            isEmployee:true,
            showQuickQuote: false,
            showLoader: true,
        }
    }

    componentDidMount(){
      let USER_DATA = JSON.parse(sessionStorage.getItem('USER_DATA'));
        let data = {
            "clientId" : this.props.clientId
        }
        axios.post(configurations.baseUrl + '/agentlogin/getClient', data)
            .then(response=>{
                if(response.data.response){
                    let clientName = response.data.response.clientName.replaceAll(' ', '');

                    // let url = configurations.AGENT_BASE_URL + clientName + '/' + this.props.agentId
                        // if(this.props.empid){
                        //     url  = url + '/' + this.props.empid
                        // }

                        let route = window.getRouteofMyPage(this.props.clientId);
                        let checkDev = process.env.REACT_APP_BUILD !== "prod" ? "&dev=true" : ""
                        let URL = process.env.REACT_APP_Agent_URL + route + "?agentID=" + this.props.agentId + checkDev;
                          this.setState({
                              agentURL :  URL 
                          })

                }
            });


      fetch(configurations.baseUrl + '/agentlogin/organizationDocList/' + this.props.clientId +'/Curam')
      .then(response => response.json())
      .then(data => {
        console.log("Accordian data---",data.response)
              this.setState({accordionData:data.response})
      });


      axios.get(configurations.baseUrl + "/agentlogin/getAgentById/" + this.props.agentId)
        .then((response) => {
          console.log("desc===", response)
          if (response.data.response) {
            this.setState({
              agent: response.data.response,
              loaderShow: false,
            }, () => {
              this.state.agentEmail = this.state.agent.email
              this.state.agentPhone = this.state.agent.phone
            });
          }
        });

        let enrollmenturl = `${process.env.REACT_APP_EMPLOYER_PORTAL}/auth/signup?ch=${btoa(USER_DATA?.clientId)}&ag=${btoa(USER_DATA?.agentId)}&emp=&mode=non-trial`;
        let quick_quote = `${process.env.REACT_APP_QUICK_ESIT_LINK}?channelID=${USER_DATA?.clientId}&agentID=${USER_DATA?.agentId}&agentEmail=${sessionStorage.getItem('email')}`
        this.setState({quick_quote_url : quick_quote , enrollment_url: enrollmenturl});
      }
    handleChange =  panel => (event, expanded) =>{
        this.setState({expanded:expanded ? panel : false})
    }

    copy = (e) => {
        if(this.state.agentURL !== ''){
           // this.state.agentURL.select();
            document.execCommand("copy");
            e.target.focus();
            this.setState({ tooltipShow:true});
            this.hideTooltip()
        }
    }

    hideTooltip=()=>{
      setInterval(() => { 
        this.setState({
          tooltipShow:false
        })
        }, 5000);
    }

    linkClick = (url, isOn) => {
      console.log('click', url);
      // if (!isOn || !this.props.disable) window.open(url)
      window.open(url)
    }

    openQuickEstimate = () => {
      this.setState({ showQuickQuote: true, showLoader: true })
    }

    closeQuickEstimate = () => {
      this.setState({ showQuickQuote: false })
      this.props.refreshTable()
    }

    render(){

      return (
        <div style={{fontFamily: "Roboto, Arial, Helvetica, sans-serif"}}>
           <div style={customStyle.linksContainer}>
            <div style={customStyle.inputContainer}>
              <div style={customStyle.linksButton} onClick={() => this.openQuickEstimate()}>
                <text>QUICK ESTIMATE</text>
              </div>
              <div style={customStyle.linksButton} onClick={() => this.linkClick(this.state.enrollment_url, true)} disabled={this.state.agentURL !== '' && this.props.disable}>
                <text>ENROLLMENT</text>
              </div> 
              <div style={customStyle.linksButton} onClick={() => this.linkClick(this.state.agentURL, true)}>
                <text>MY PAGE</text>
              </div>
            </div>
          </div>

          {(this.state.accordionData && this.state.accordionData.agent) &&
            <Accordion expanded={this.state.isAgent} onChange={() => this.setState({isAgent: !this.state.isAgent})}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
                <Typography style={customStyle.accordioanLabel}>Agent Resources</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} lg={12}>

                {Object.keys(this.state.accordionData?.agent).map(lang => (
              <LanguageAccordion key={lang} lang={lang} docs={this.state.accordionData.agent[lang]} />
            ))}

                </Grid>
              </AccordionDetails>
            </Accordion>
          }

{(this.state.accordionData && this.state.accordionData.employer) &&
            <Accordion expanded={this.state.isMember} onChange={() => this.setState({isMember: !this.state.isMember})}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
                <Typography style={customStyle.accordioanLabel}>Employer & Prospect Resources</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} lg={12}>

                {Object.keys(this.state.accordionData?.employer).map(lang => (
              <LanguageAccordion key={lang} lang={lang} docs={this.state.accordionData.employer[lang]} />
            ))}

                </Grid>
              </AccordionDetails>
            </Accordion>
          }

{(this.state.accordionData && this.state.accordionData.employee) &&
            <Accordion expanded={this.state.isEmployee} onChange={() => this.setState({isEmployee: !this.state.isEmployee})}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
                <Typography style={customStyle.accordioanLabel}>Employee Resources</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12} sm={12} lg={12}>

                {Object.keys(this.state.accordionData?.employee).map(lang => (
              <LanguageAccordion key={lang} lang={lang} docs={this.state.accordionData.employee[lang]} />
            ))}

                </Grid>
              </AccordionDetails>
            </Accordion>
          }

<Modal
          show={this.state.showQuickQuote}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // dialogClassName="modal_welcome_container"
          onHide={(event) => this.closeQuickEstimate()}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#201547", height: 50, alignItems: 'center' }}>
            <Modal.Title style={{ color: "#F3D03E", fontSize: 18, fontWeight: 'bold' }}>Quick Estimate</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "overflow-y": "auto",
              "padding": 0,
              "background-color": "#201547"
            }}
          >
            <Loader showLoader={this.state.showLoader} style={{height: '85vh'}} color={"secondary"} />
            <iframe
              src={this.state.quick_quote_url}
              title="Common Module"
              style={{
                width: '100%',
                height: "80vh",
                border: 'none',
                margin: 'auto'
              }}
              onLoad={() => this.setState({showLoader: false})}
            />
          </Modal.Body>
        </Modal>

        </div>
      );
    }
    }

export default CustomizedAccordions;