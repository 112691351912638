import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import customStyle from '../../Assets/CSS/stylesheet_UHS';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding: '0px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: '16px',
    paddingRight: '0px',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const LanguageAccordion = ({ lang, docs }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${lang}-content`} id={`${lang}-header`} style={{ backgroundColor: '#ffffff', minHeight: "45px" }}>
        <Typography style={customStyle.accordioanLabel}>{lang}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12} sm={12} lg={12}>
          {docs.map(doc => (
            <Typography component={'ul'} key={doc.title} style={{ paddingLeft: '10px', margin: '5px' }}>
              <a href={doc.docLink} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer', marginRight: '7px', fontFamily: "Roboto, Arial, Helvetica, sans-serif", fontSize: '14px' }} target='_blank' rel='noopener noreferrer'>
                {doc.title}
              </a>
            </Typography>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LanguageAccordion
